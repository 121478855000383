import React from "react";
import Bannercontact from "../components/Bannercontact";
import Contactform from "../components/Contactform";
import Contactinfo from "../components/Contactinfo";
import Footer from "../components/Footer";

function About() {
  return (
    <div>
      <React.Fragment>
        <Bannercontact />
        <Contactinfo />
        <Contactform />

        <div
          className="container"
          style={{ width: "100%", marginTop: "50px", marginBottom: "20px" }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3454.135628367251!2d30.982436215114923!3d30.032966581886456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2cfab4f7ce0f8372!2zMzDCsDAxJzU4LjciTiAzMMKwNTknMDQuNyJF!5e0!3m2!1sen!2seg!4v1676993810365!5m2!1sen!2seg"
            width="100%"
            height="200"
            style={{ border: "0" }}
            ></iframe>
        </div>
        <Footer />
      </React.Fragment>
    </div>
  );
}

export default About;
