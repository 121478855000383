import react, { useEffect, useState } from 'react';
function Aboutwrapper() {

    const [aboutus, setAboutus] = useState([]);
    
   useEffect(() => {
    fetch('https://admin.casa-eg.com/api/v1/topic/1')
       .then((response) => response.json())
       .then((data) => {
          console.log(Object.values(data.topic[0]));

          setAboutus([Object.values(data.topic[0])]);
       })
       .catch((err) => {
          console.log(err.message);
       });
       
 }, []);

 const [aboutimages1, setAboutimages1] = useState([]);
    
   useEffect(() => {
    fetch('https://admin.casa-eg.com/api/v1/topic/1')
       .then((response) => response.json())
       .then((data) => {
          console.log(Object.values(data.topic[0].photos[0]));

          setAboutimages1([Object.values(data.topic[0].photos[0])]);
       })
       .catch((err) => {
          console.log(err.message);
       });
       
 }, []);

 const [aboutimages2, setAboutimages2] = useState([]);
    
   useEffect(() => {
    fetch('https://admin.casa-eg.com/api/v1/topic/1')
       .then((response) => response.json())
       .then((data) => {
          console.log(Object.values(data.topic[0].photos[1]));

          setAboutimages2([Object.values(data.topic[0].photos[1])]);
       })
       .catch((err) => {
          console.log(err.message);
       });
       
 }, []);

  return (
<div>
<section class="ttm-row welcome-section clearfix" style={{marginTop:'200px', marginBottom:'20px'}}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-8">
                            <div class="d-flex">
                            {aboutimages1.map((about) => {
                                    return (
                                <div class="col">
                              
                                    <div class="ttm_single_image-wrapper pt-50">
                                        <img width="320" height="800" class="img-fluid" src={about[2]} alt="single_02" />
                                    </div>
                                </div>
                                )}
                            )}
                                <div class="col">
                                {aboutimages2.map((about) => {
                                    return (
                                    <div class="ttm_single_image-wrapper">
                                        <img width="320" height="800" class="img-fluid" src={about[2]} alt="single_03" />
                                    </div>
                                    )}
                            )}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-12">
                            <div class="pt-40 res-991-pt-0 res-991-mt-45">
                             
                                <div class="section-title">
                                    <div class="title-header">
                                        <h3>About Us</h3>
                                        <h2 class="title">Partner In Inspiring And Improving Your Life</h2>
                                    </div>
                                </div>
                                {aboutus.map((about) => {
                                    return (
                                <div class="pb-15 res-991-pb-0">
                                    <p className="text-justify">{about[2]}</p>
                                </div>
                                )}
                            )}
                                <div class="row">
                                    <div class="col-md-6">
                                        <ul class="ttm-list ttm-list-style-icon">
                                            <li>
                                                <span class="ttm-list-li-content">No hidden commission</span>
                                            </li>
                                            <li>
                                                <span class="ttm-list-li-content">3D Design Included</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6">
                                        <ul class="ttm-list ttm-list-style-icon">
                                        <li>
                                                <span class="ttm-list-li-content">Fully Vastu Optimized</span>
                                            </li>
                                            <li>
                                                <span class="ttm-list-li-content">Personalized Designs</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
  </div>
  )
}

export default Aboutwrapper