import "./App.css";
import PublicRoute from "./PublicRoute";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { BsTelephone } from "react-icons/bs";
import Login from "./components/frontend/auth/Login";
import Register from "./components/frontend/auth/Register";
import Page403 from "./components/errors/Page404";
import Page404 from "./components/errors/Page404";
import axios from "axios";
import ContactUsBtn from "./layouts/ContactUsButton/ContactUsBtn";
import React from "react";
import Project from "./components/Project";

axios.defaults.baseURL = "http://localhost:8000/";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

function App() {
  const [app_state, set_app_state] = React.useState({ show_button: false });

  React.useEffect(() => {
    let timer_id = undefined;
    function onScroll(event) {
      // debugger;
      if (timer_id) {
        clearTimeout(timer_id);
      }
      timer_id = setTimeout(() => {
        if (window.scrollY !== 0) {
          set_app_state((old) => {
            return { ...old, show_button: true };
          });
          return;
        }
        set_app_state((old) => {
          return { ...old, show_button: false };
        });
      }, 500);

      console.log(window.scrollY);
    }
    const window_element = window;
    window_element?.addEventListener("scroll", onScroll);
    console.log("add", window_element);

    return function cleanup() {
      console.log("clean up");
      window_element?.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div className="App">
      <Router>
        <ContactUsBtn
          name={<BsTelephone style={{ width: "20px", height: "20px" }} />}
          active={app_state.show_button}
          path="/contact"
        />

        <Switch>
          <PublicRoute path="/" name="Home" />

          <Route path="/login">
            {localStorage.getItem("auth_token") ? (
              <Redirect to="/" />
            ) : (
              <Login />
            )}
          </Route>
          <Route path="/register">
            {localStorage.getItem("auth_token") ? (
              <Redirect to="/" />
            ) : (
              <Register />
            )}
          </Route>
          <Route path="/403" component={Page403} />
          <Route path="/404" component={Page404} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
