fetch('https://admin.casa-eg.com/api/v1/banners/1')
   .then(response => response.json())
   .then(data => console.log(data.banners));

export const SliderData = [
{
title:'interior design consultancy firm ',
titletitle:'Ready to create your dreams innovate creative design',
price:'call: 7878787',
path:'/homes',
label:'Read more',
image: 'assets/images/single-cover-img01.jpg' ,
alt:'house'
},
{
    title:'interior design consultancy firm ',
    titletitle:'Ready to create your dreams innovate creative design',
    price:'call: 7878787',
    path:'/homes',
    label:'Read more',
    image: 'assets/images/bg-image/col-bgimage-4.jpg',
    alt:'house'
    }
];
