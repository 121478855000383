import React from 'react';
import {Link} from 'react-router-dom';
function Page404() {
    return(
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card card-body">
                        <h1>Page 404 | Page Not Fount </h1>
                        <h3>Url / Page you are searching not found</h3>
                        <Link to="/">go to home</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page404;
