import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './BrandStyles.css';
import Logo from '../assets/img/logo.png';
import axios from 'axios';

function Brands() {
  const [Clients, setClients] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await axios('https://admin.casa-eg.com/api/v1/topics/9/page/1/count/10');
      setClients(result.data.topics);
    };

    fetchData();
  }, []);

  return (
    <div>
      <AliceCarousel disableDotsControls autoWidth>
        {Clients.map((client, index) => (
          <div className="item" key={index}>
            <div class="client-box">
              <div class="client-thumbnail">
                <img
                  style={{ backgroundColor: '#181A1F', borderRadius: '10px' }}
                  width="178"
                  height="70"
                  class="img-fluid"
                  src={client.photo_file}
                  alt={client.title}
                />
              </div>
            </div>
          </div>
        ))}
      </AliceCarousel>
    </div>
  );
}

export default Brands;
