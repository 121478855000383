import React from "react";

const MainTitle = (props) => {
  return (
    <div class="section-title ">
      <div class="title-header">
        <h3 className="">{props?.largeTitle}</h3>
        <h2 class="title text-center">{props?.secondTitle}</h2>
      </div>
      <p className="text-cetnet">{props?.description}</p>
    </div>
  );
};

export default MainTitle;
