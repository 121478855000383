import React from 'react';
import axios from 'axios';
import { Puff } from 'react-loader-spinner';



function Contactinfo() {

    const [Contactus, setContactus] = React.useState([]);
    const [loading, setLoading] = React.useState(true);


    React.useEffect(() => {
      const fetchData = async () => {
        const result = await axios('https://admin.casa-eg.com/api/v1/website/contacts');
        setContactus(result.data.details);
        setLoading(false);
      };
  
      fetchData();
    }, []);

  return (
    <div>
      <section class="ttm-row pt-85 res-991-pt-45 pb-0 contact-section res-991-pb-0 clearfix">
                <div class="container">
                {loading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center",}}>
              <Puff
                height="80"
                width="80"
                radius="9"
                color="#ad7f47"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
                style={{ margin: "auto" }}
              />
              </div>
              ) : (
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="featured-icon-box icon-align-top-content style7">
                                <div class="featured-inner">
                                    <div class="featured-icon">
                                        <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md ttm-icon_element-style-square"> 
                                  
                                        </div>
                                    </div>
                                    
                                    <div class="featured-content">
                                        <div class="featured-title">
                                            <h3>Phone Number</h3>
                                        </div>
                                        <div class="featured-desc">
                                            <p>{Contactus.phone}</p>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
              
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                 
                            <div class="featured-icon-box icon-align-top-content style7">
                                <div class="featured-inner">
                                    <div class="featured-icon">
                                        <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md ttm-icon_element-style-square"> 
                               
                                        </div>
                                    </div>
                                    <div class="featured-content">
                                        <div class="featured-title">
                                            <h3>Email Address</h3>
                                        </div>
                                        <div class="featured-desc">
                                            <p>{Contactus.email}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                
                            <div class="featured-icon-box icon-align-top-content style7">
                                <div class="featured-inner">
                                    <div class="featured-icon">
                                        <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md ttm-icon_element-style-square"> 
                         
                                        </div>
                                    </div>
                                    <div class="featured-content">
                                        <div class="featured-title">
                                            <h3>Visit Us</h3>
                                        </div>
                                        <div class="featured-desc">
                                            <p>{Contactus.address}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                        
                            <div class="featured-icon-box icon-align-top-content style7">
                                <div class="featured-inner">
                                    <div class="featured-icon">
                                        <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md ttm-icon_element-style-square"> 
                                  
                                        </div>
                                    </div>
                                    <div class="featured-content">
                                        <div class="featured-title">
                                            <h3>Working Hours</h3>
                                        </div>
                                        <div class="featured-desc">
                                            <p>{Contactus.working_time}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </section>
    </div>
  )
}

export default Contactinfo
