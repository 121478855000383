import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Services from '../pages/Services';
import Projects from '../pages/Projects';
import ProjectDetails from '../pages/Projectdetails';
const publicRoutesList = [
    { path: '/', exact: true, name: 'Home', component: Home },
    {path:'/about', exact: true, name: 'About', component: About},
    {path:'/contact',exact: true, name: 'Contact', component: Contact},
    {path:'/services', exact: true, name: 'Services', component:Services},
    {path:'/projects', exact: true, name: 'Projects', component: Projects},
    {path:'/:id', exact: true, name: 'Project Details', component: ProjectDetails}
];

export default publicRoutesList;