import React from "react";
import Service from "../components/Service";
import Footer from "../components/Footer";
import Bannerservice from "../components/Bannerservice";
import LargeCard from "../components/largeCard/LargeCard";
import Services_DATA from "../components/data/ServicesData";
import SectionTitle from "../components/SectionTitle/SectionTitle";
import "./largecard.css";
import axios from "axios";
import { Puff } from 'react-loader-spinner';

function Services() {
  const [services, setServices] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await axios('https://admin.casa-eg.com/api/v1/topics/2/page/1/count/3');
      setServices(result.data.topics);
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div>
      <React.Fragment>
        <Bannerservice />
        <div className="contaienr">
          <SectionTitle
            title="Services we do"
            description="Company providing the best design services"
          />
        </div>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "335px", paddingBottom: "105px"}}>
            <Puff
              height="80"
              width="80"
              radius="9"
              color="#ad7f47"
              ariaLabel="loading"
              wrapperStyle
              wrapperClass
              style={{ margin: "auto" }}
            />
          </div>
        ) : (
          <section className="services-page_section">
            <div className="container">
              {services.map((service, index) => {
                const isEven = index % 2 === 0;
                const cardClass = isEven ? "large-card" : "large-card large-card--reverse";

                return (
                  <div className={cardClass} key={index}>
                    <div className="large-card__right">
                      <div className="large-card__right__img">
                        <img src={service.photo_file} alt={service.title} />
                      </div>
                    </div>
                    <div className="large-card__left">
                      <h3 className="large-card__left__title">{service.title}</h3>
                      <p className="large-card__left__description">{service.details}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        )}
        <Footer />
      </React.Fragment>
    </div>
  );
}

export default Services;
