import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./Projectdetails.css";
import Bannerproject from "../components/Bannerproject";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Footer from "../components/Footer";
import { Puff } from 'react-loader-spinner';

const ProjectDetails = () => {
  let { id } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://admin.casa-eg.com/api/v1/topic/${id}`);
        const data = await response.json();
        const [project] = data.topic;
        setProject(project);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);

  return (
    <div>
      <React.Fragment>
        <Bannerproject />
        <div className="container">
          <div className="project-details-section">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Puff
                  height="80"
                  width="80"
                  radius="9"
                  color="#ad7f47"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                  style={{ margin: "auto" }}
                />
              </div>
            ) : (
              <>
                <h2 className="project-title">{project.title}</h2>
                <div className="project-image-container">
                  <ImageGallery
                    items={project.photos.map((photo) => ({
                      original: photo.url,
                      thumbnail: photo.url,
                    }))}
                    showNav={true}
                    showThumbnails={true}
                    autoPlay={true}
                    slideInterval={3000}
                    slideOnThumbnailOver={true}
                    thumbnailWidth="100"
                    thumbnailHeight="56"
                  />
                </div>
                <p className="project-details-text">{project.details}</p>
              </>
            )}
          </div>
        </div>
        <Footer />
      </React.Fragment>
    </div>
  );
};

export default ProjectDetails;
