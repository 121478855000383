import React from "react";
import { Link } from "react-router-dom";
import "./contactusBtn.css";
const ContactUsBtn = (props) => {
  return <Link to={props.path}>
    <button className={`contactus-btn ${props.active?"active":""}`}>{props.name}</button>
  </Link>;
};

export default ContactUsBtn;
