import React , {useEffect , useState, useRef} from 'react'
import styled , {css} from 'styled-components/macro'
import { IoMdArrowRoundForward } from 'react-icons/io'
import { IoMdArrowForward ,IoArrowForward , IoArrowBack } from 'react-icons/io5'
import axios from 'axios';


const HeroSection = styled.section`
height: 60vh;
max-height: 1100px;
position: static;
overflow:hidden;
top:0;
`;
const HeroWrapper = styled.div`
width:100%;
height:100%;
display:flex;
justify-content:center;
align-items:center;
overflow:hidden;
position:static;
`;
const HeroSlide = styled.div`
z-index:1;
width:100%;
height:100%;
`;
const HeroSlider = styled.div`
position:absolute;
top:0;
left:0;
width:100%;

display:flex;
align-items:center;
justify-content:center;
&::before{
content:'';
position:absolute;
z-index:2;
width:100%;
height:100vh;
bottom:0vh;
left:0;
bottom:0;
right:0;
overflow:hidden;
}
`;

const HeroImage = styled.img`
position:absolute;
top:0;
left:0;
width:100vw;
height:50vh;
object-fit:cover;
transform:scale(1.5);
transition:0.9s;
animation: imageAnimation 15s linear infinite 0s; 

@keyframes imageAnimation { 
  0% { opacity: 0.5; animation-timing-function: ease-in; }
  8% { opacity: 1; animation-timing-function: ease-out; }
  17% { opacity: 1; animation-timing-function: ease-in; }
  25% { opacity: 0.5; animation-timing-function: ease-in; }
  100% { opacity: 0.5; animation-timing-function: ease-in; }
}
`;

const HeroContent = styled.div`
position:relative;
z-index:10;
color:#fff !important;
animation: titleAnimation 36s linear infinite 0s; 

h1 {
font-size:24px;
font-weight:400;
text-transform:capitalize;
text-shadow:0px 0px 20px rgba(0,0,0,0.4);
text-align:center;
margin-bottom:10px;
color:#fff !important;
}

h6{
  font-size:9px;
  font-weight:400;
text-transform:capitalize;
text-shadow:0px 0px 20px rgba(0,0,0,0.4);
text-align:left;
margin-bottom:10px;
color:#fff !important;
}
`;
const Arrow  = styled(IoMdArrowRoundForward )`
`; 
const SliderButtons = styled.div`
position:absolute;
right:0;
top:52%;
display:flex;
z-index:1;

`;


const PrevArrow = styled(IoArrowBack)`
width:50px;
height:50px;
color:#fff;
background:#000d1a;
border-radius:50px;
padding:10px;
margin-right:1rem;
user-select:none;
transition:0.3s;

&:hover{
  background:#cd853f;
  transform:scale(1.05);
}
`;
const NextArrow = styled(IoArrowForward)`
width:50px;
height:50px;
color:#fff;
background:#000d1a;
border-radius:50px;
padding:10px;
margin-right:1rem;
user-select:none;
transition:0.3s;

&:hover{
  background:#cd853f;
  transform:scale(1.05);
}
`;

/* const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch('https://admin.casa-eg.com/api/v1/banners/1');
      const json = await res.json();
      setData(json);
    };
    fetchData();
  }, []); */

    

const Hero = ({ slides }) => {
  const [current,setCurrent] = useState(0);
  const length = slides.length;
  const timeout = useRef(null);
  useEffect(() => {
    const nextSlide = () => {
      setCurrent(current === length -1 ? 0 : current + 1);
      console.log(current);
    };
timeout.current = setTimeout(nextSlide, 3000)
return function () {
  if(timeout.current)
  {
    clearTimeout(timeout.current)
  }
};
  },[current,length])


  const nextSlide = () => {
    if(timeout.current)
    {
      clearTimeout(timeout.current)
    }
    setCurrent(current === length -1 ? 0 : current + 1);
    console.log(current);
  };
  const PrevSlide = () => {
    if(timeout.current)
    {
      clearTimeout(timeout.current)
    }
    setCurrent(current === 0 ? length - 1 : current - 1);
    console.log(current);
  };

  const [banners, setBanners] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await axios('https://admin.casa-eg.com/api/v1/banners/1');
      setBanners(result.data.banners);
    };

    fetchData();
  }, []);


  return (
  <HeroSection>
    <HeroWrapper>
{banners.map((banner,index) => {
  return(
        <HeroSlide key={index}>
          {index === current && (
    <HeroSlider Fade={true}>
    <HeroImage src={banner.file} alt={banner.title} />
    <div className='container' style={{margin:'11.4rem 0' }}>
    <HeroContent>

    <h1>{banner.title}</h1>
    {/* <br/>
  <h1>
    {banner.title}</h1> */}
<h6 style={{fontSize:'12px !important'}}>
</h6>

    </HeroContent>
    </div>
    </HeroSlider>
          )}

    </HeroSlide>
  )
})}
<SliderButtons>
  <PrevArrow onClick={PrevSlide}/>
  <NextArrow onClick={nextSlide} />
</SliderButtons>
    </HeroWrapper>
  </HeroSection>

  );
};

export default Hero
