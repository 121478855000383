import React , {useState , useEffect} from 'react'
import { Link } from 'react-router-dom'
import {HiOutlineMenuAlt3} from 'react-icons/hi'
import {AiOutlineClose} from 'react-icons/ai'
import './NavbarStyles.css'
import {FaFacebook , FaInstagram , FaTwitter , FaPinterest , FaYoutube, FaWhatsapp} from 'react-icons/fa'
import axios from 'axios'
function Navbar() {
    const [nav , setNav] = useState(false);
    const handleNav = () => setNav(!nav)

   
         /* Method that will fix header after a specific scrollable */
         const isSticky = (e) => {
            const header = document.querySelector('.navbar');
            const scrollTop = window.scrollY;
            scrollTop >= 150 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
        };
    
        // Sticky Menu Area
        useEffect(() => {
            window.addEventListener('scroll', isSticky);
            return () => {
                window.removeEventListener('scroll', isSticky);
            };
        });

        const [social, setSocial] = React.useState([]);

    React.useEffect(() => {
      const fetchData = async () => {
        const result = await axios('https://admin.casa-eg.com/api/v1/website/social');
        setSocial(result.data.details);
      };
  
      fetchData();
    }, []);
    
  return (
    <div className={nav ? 'navbar navbar-bg' : 'navbar' } >
        <Link to="/">
<div className={nav ? 'logo dark' : 'logo'}>
      <img width="100" height="50"  class="img-fluid" src={'assets/images/logo.png'} alt="image" />
      </div>
      </Link>
{/* <div className={nav ? 'logo dark' : 'logo logo2'}>
 <Link to="/">  <img width="80" height="50" class="img-fluid d-lg-none d-block" src={'assets/images/logo.png'} alt="image" />
     </Link></div> */}
<ul className="nav-menu mx-auto" id="nvmenu">
<li><Link to="/">Home</Link></li>
<li><Link to="/about">About Us</Link></li>

      <li><Link to="/services">Services</Link></li>
      <li><Link to="/projects">Project</Link></li>
      <li><Link to="/contact">Contact Us</Link></li>
</ul>

<div className="hamburger" onClick={handleNav}>
    {!nav ? (<HiOutlineMenuAlt3  className='icon' />) : (<AiOutlineClose style={{color : '#000'}} className='icon'/>) }
</div>
<div className={nav ? 'mobile-menu active' : 'mobile-menu'} id="navmob">
    <ul className="mobile-nav">
    <li><Link to="/" onClick={handleNav}>Home</Link></li>
<li><Link to="/about" onClick={handleNav}>About Us</Link></li>
<li><Link to="/services" onClick={handleNav}>Services</Link></li>
      <li><Link to="/projects" onClick={handleNav}>Project</Link></li>
      <li><Link to="/contact" onClick={handleNav}>Contact Us</Link></li>
    </ul>
    <div className="mobile-menu-bottom">

        <div className="social-icons">
<FaFacebook className="icon" href={social.facebook}/>
<FaInstagram className="icon" href={social.instagram}/>
<FaWhatsapp className="icon" href={social.whatsapp}/>
        </div>
    </div>
</div>
    </div>
  )
}

export default Navbar
