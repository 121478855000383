import React from 'react'
import Abouthome from '../components/Abouthome'
import Categoryhome from '../components/Categoryhome'
import Featurehome from '../components/Featurehome'
import Hero from '../components/Hero'
import { SliderData } from '../components/data/SliderData';
import Newarrivals from '../components/Newarrivals'
import Footer from '../components/Footer'
import Brands from '../components/Brands'
import MainTitle from '../components/MainTitle/MainTitle'
export default function Home() {
  return (
    <React.Fragment>

<Hero  slides={SliderData} />
<Abouthome  />
<Categoryhome />
<Newarrivals />
{/* <Featurehome /> */}
<div className='container pt-5 '>
<div className='row'>
<MainTitle className='col-12' largeTitle='Our Clients' secondTitle='We Are a real state developer' description=' We Are a real state developer with a focus on creativity ,
vision and community'/>

</div>
</div>
<Brands />
<Footer />





    </React.Fragment>
  )
}
