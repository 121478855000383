import React, { useState } from 'react';
import axios from 'axios';

function Contactform() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    api_key: '402784613679330',
    contact_subject: 'New form submission',
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    contact_message: '',
    project: '',
    location_unit: '',
    size_unit: '',
    service: ''
  });
  const [selectedService, setSelectedService] = useState('');

  const handleInputChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);

    axios
      .post('https://admin.casa-eg.com/api/v1/contact', formData)
      .then(response => {
        setLoading(false);
        setSuccess(true);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  };

  const handleServiceChange = event => {
    setSelectedService(event.target.value);
    setFormData({
      ...formData,
      contact_subject: event.target.value
    });
  };

  return (

    <div>
      <section class="ttm-row conatact-section bg-layer-equal-height mt_15 clearfix">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="col-bg-img-eight ttm-bg ttm-col-bgimage-yes ttm-textcolor-white spacing-11 z-index-2">
                <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div class="layer-content"></div>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="ttm-bg ttm-col-bgcolor-yes ttm-right-span spacing-12">
                <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div class="layer-content">
                  <div class="section-title">
                    <div class="title-header">
                      <h2 class="title">Send Your Message To Us</h2>
                    </div>
                  </div>
                  <div class="padding_top30">
                    <form
                      id="contact_form"
                      class="contact_form wrap-form clearfix"
                      onSubmit={handleSubmit}
                    >
                      <div class="row ttm-boxes-spacing-20px">
                        <div class="col-md-12">
                          <label>
                            <span class="text-input">
                              <input
                                name="contact_name"
                                type="text"
                                value={formData.contact_name}
                                placeholder="Name..."
                                required="required"
                                onChange={handleInputChange}
                              />
                            </span>
                          </label>
                        </div>
                        <div class="col-md-6">
                          <label>
                            <span class="text-input">
                              <input
                                name="contact_email"
                                type="email"
                                value={formData.contact_email}
                                placeholder="Email..."
                                required="required"
                                onChange={handleInputChange}
                              />
                            </span>
                          </label>
                        </div>
                        <div class="col-md-6">
                          <label>
                            <span class="text-input">
                              <input
                                name="contact_phone"
                                type="text"
                                value={formData.contact_phone}
                                placeholder="Phone Number..."
                                required="required"
                                onChange={handleInputChange}
                              />
                            </span>
                          </label>
                        </div>
                        <div class="col-md-12">
                          <label>
                            <span class="text-input">
                              <select
                                name="service"
                                value={formData.service}
                                onChange={handleInputChange}
                                required="required"
                              >
                                <option value="">Select a Service</option>
                                <option value="interior_design">Interior Design</option>
                                <option value="real_estate_development">
                                  Real Estate Development
                                </option>
                                <option value="maintenance">Maintenance</option>
                              </select>
                            </span>
                          </label>
                        </div>
                        {formData.service === "interior_design" && (
                          <>
                            <div class="col-md-6">
                              <label>
                                <span class="text-input">
                                  <input
                                    name="location_unit"
                                    type="text"
                                    value={formData.location_unit}
                                    placeholder="Location Unit..."
                                    required="required"
                                    onChange={handleInputChange}
                                  />
                                </span>
                              </label>
                            </div>
                            <div class="col-md-6">
                              <label>
                                <span class="text-input">
                                  <input
                                    name="size_unit"
                                    type="text"
                                    value={formData.size_unit}
                                    placeholder="Size Unit..."
                                    required="required"
                                    onChange={handleInputChange}
                                  />
                                </span>
                              </label>
                            </div>
                            <div class="col-md-12">
                              <label>
                                <span class="text-input">
                                  <textarea
                                    class="col-md-12"
                                    name="contact_message"
                                    value={formData.contact_message}
                                    placeholder="Enter message..."
                                    required="required"
                                    onChange={handleInputChange}
                                  />
                                </span>
                              </label>
                            </div>
                            <div class="col-md-12">
                              <button
                                class="ttm-btn ttm-btn-size-md ttm-btn-shape-squar ttm-btn-style-border ttm-icon-btn-right ttm-btn-color-darkgrey mt-40 res-991-mt-30"
                                type="submit">Submit
                              </button>
                            </div>
                          </>
                        )}

                        {formData.service === "real_estate_development" && (

                          <>
                            <div>
                              <div class="col-md-6">
                                <label>
                                  <span class="text-input">
                                    <input
                                      name="location_unit"
                                      type="text"
                                      value={formData.location_unit}
                                      placeholder="Location Unit..."
                                      required="required"
                                      onChange={handleInputChange}
                                    />
                                  </span>
                                </label>
                              </div>
                              <div class="col-md-6">
                                <label>
                                  <span class="text-input">
                                    <input
                                      name="size_unit"
                                      type="text"
                                      value={formData.size_unit}
                                      placeholder="Size Unit..."
                                      required="required"
                                      onChange={handleInputChange}
                                    />
                                  </span>
                                </label>
                              </div>
                              <div class="col-md-12">
                                <label>
                                  <span class="text-input">
                                    <input
                                      name="project"
                                      type="text"
                                      value={formData.project}
                                      onChange={handleInputChange}
                                      placeholder="Your Project Name..."
                                      required="required"
                                    >
                                     {/*  <option value="">Select Project</option>
                                      <option value="project_1">Project 1</option>
                                      <option value="project_2">Project 2</option>
                                      <option value="project_3">Project 3</option> */}
                                    </input>
                                  </span>
                                </label>
                              </div>
                              <div class="col-md-12">
                                <label>
                                  <span class="text-input">
                                    <textarea
                                      class="col-md-12"
                                      name="message"
                                      value={formData.contact_message}
                                      placeholder="Enter message..."
                                      required="required"
                                      onChange={handleInputChange}
                                    />
                                  </span>
                                </label>
                              </div>
                              <div class="col-md-12">
                                <button
                                  class="ttm-btn ttm-btn-size-md ttm-btn-shape-squar ttm-btn-style-border ttm-icon-btn-right ttm-btn-color-darkgrey mt-40 res-991-mt-30"
                                  type="submit">Submit
                                </button>
                              </div>
                            </div>
                          </>
                        )}

                        {formData.service === "maintenance" && (
                          <>
                            <div class="col-md-12">
                              <label>
                                <span class="text-input">
                                  <textarea
                                    class="col-md-12"
                                    name="message"
                                    value={formData.contact_message}
                                    placeholder="Enter message..."
                                    required="required"
                                    onChange={handleInputChange}
                                  />
                                </span>
                              </label>
                            </div>
                            <div class="col-md-12">
                              <button
                                class="ttm-btn ttm-btn-size-md ttm-btn-shape-squar ttm-btn-style-border ttm-icon-btn-right ttm-btn-color-darkgrey mt-40 res-991-mt-30"
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>
                          </>
                        )}

                        {success && (
                          <div class="col-md-12">
                            <div class="alert alert-success" role="alert">
                              Message sent successfully!
                            </div>
                          </div>
                        )}

                        {loading && (
                          <div class="col-md-12">
                            <div class="alert alert-info" role="alert">
                              Sending message...
                            </div>
                          </div>
                        )}
                      </div>
                      </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  );
}

export default Contactform;