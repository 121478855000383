import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../assets/img/logo.png";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import axios from 'axios';

function Newarrivals() {

    const [projectsnew, setProjectsnew] = React.useState([]);

    React.useEffect(() => {
      const fetchData = async () => {
        const result = await axios('https://admin.casa-eg.com/api/v1/topics/8/page/1/count/5');
        setProjectsnew(result.data.topics);
      };
  
      fetchData();
    }, []);

  return (
    <div>
     
     <section class="ttm-row bottom_zero_padding-section mt_10 res-991-mt-0 bg-layer-equal-height clearfix">
                <div class="container">
         
                    <div class="row">
                        <div class="col-lg-10 col-md-8">
                     
                            <div class="section-title style2">
                                <div class="title-header">
                                    <h3>What's new</h3>
                                    <h2 class="title">Completed Projects</h2>
                                </div>
                                <div class="title-desc">
                                    <p className="text-justify">Each project is designed with close attention to the detail, managed and implemented with great care. We work with craftsmen and bespoke</p>
                                </div>
                            </div>
                        </div>
                         <div class="col-lg-2 col-md-4">
                            <Link class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-darkgrey mt-10 mb-10 float-md-right res-991-mb-30 res-991-mt-0" to="/projects">View More</Link>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
          
                    <div class="row">
                        <div class="tm_coverimgbox_wrapper">

                        {projectsnew.map((project, index) => {
                            return(
                            <div class="tm_coverbox_contents" key={index}>
                            <div className='tm-img'>
                            { project.photo_file === null ? (
                                <img src={logo} style={{width:'100%'}}/>
                            ) : (
                                <img src={project.photo_file} style={{width:'100%'}}/>
                            )}
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title">
                                        <h3>{project.title}</h3>
                                    </div>
                                    {/* <div class="featured-desc">
                                      <p>{project.details}</p>
                                    </div> */}
                                    <div class="ttm-footer">
                                        <Link class="ttm-btn ttm-btn-size-md ttm-btn-shape-squar ttm-btn-style-border ttm-icon-btn-right ttm-btn-color-white" to="/projects" tabindex="0">Read More</Link>
                                    </div>
                                </div>
                                <div class="coverbox-img-reposive">
                                    
                                </div>
                            </div>
                            )}
                        )
                        }
                            <div class="tm_coverbox_img">
                                <div class="tm_box_overlay"></div>
                                <div class="tm-stepbox-imagebox">
                                    <div class="tm-ptable-icon-type-image">
                                  
                                    </div>
                                </div>
                            </div>
                            
                            {/* <div class="tm_coverbox_contents">
                                <div className='tm-img'>
                                <img src={logo} style={{width:'100%'}}/>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title">
                                        <h3>Modern Kitchen</h3>
                                    </div>
                                    <div class="featured-desc">
                                        <p>The style of the interior was defined modern in light colors. The main materials used in the project.</p>
                                    </div>
                                    <div class="ttm-footer">
                                        <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-squar ttm-btn-style-border ttm-icon-btn-right ttm-btn-color-white" href="#" tabindex="0">Read More</a>
                                    </div>
                                </div>
                                <div class="coverbox-img-reposive">
                             
                                </div>
                            </div>
                            <div class="tm_coverbox_img">
                                <div class="tm_box_overlay"></div>
                                <div class="tm-stepbox-imagebox">
                                    <div class="tm-ptable-icon-type-image">
                                    
                                    </div>
                                </div>
                            </div>
                            <div class="tm_coverbox_contents">
                            <div className='tm-img'>
                                <img src={logo} style={{width:'100%'}}/>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title">
                                        <h3>Prestige Villa</h3>
                                    </div>
                                    <div class="featured-desc">
                                        <p>Prestige villa exterior design thought and decided that in such a case it would be wise to use porcelain stoneware.</p>
                                    </div>
                                    <div class="ttm-footer">
                                        <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-squar ttm-btn-style-border ttm-icon-btn-right ttm-btn-color-white" href="#" tabindex="0">Read More</a>
                                    </div>
                                </div>
                                <div class="coverbox-img-reposive">
                                
                                </div>
                            </div>
                            <div class="tm_coverbox_img active">
                                <div class="tm_box_overlay"></div>
                                <div class="tm-stepbox-imagebox">
                                    <div class="tm-ptable-icon-type-image">
                                   
                                    </div>
                                </div>
                            </div>
                            <div class="tm_coverbox_contents">
                            <div className='tm-img'>
                                <img src={logo} style={{width:'100%'}}/>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title">
                                        <h3>Family Apartment</h3>
                                    </div>
                                    <div class="featured-desc">
                                        <p>An apartment, or flat is a self-contained housing unit type of residential occupies only part of a building.</p>
                                    </div>
                                    <div class="ttm-footer">
                                        <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-squar ttm-btn-style-border ttm-icon-btn-right ttm-btn-color-white" href="#" tabindex="0">Read More</a>
                                    </div>
                                </div>
                                <div class="coverbox-img-reposive">
                                
                                </div>
                            </div>
                            <div class="tm_coverbox_img">
                                <div class="tm_box_overlay"></div>
                                <div class="tm-stepbox-imagebox">
                                    <div class="tm-ptable-icon-type-image">
                                   
                                    </div>
                                </div>
                            </div>  */}
                        </div>
                    </div>
                </div>
            </section>
    </div>
  )
}

export default Newarrivals