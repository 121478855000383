import React from 'react'

import Bannerproject from '../components/Bannerproject'
import Footer from '../components/Footer'
import Project from '../components/Project'
import ProjectDetails from './Projectdetails'
function Projects() {
  return (
    <div>
       <React.Fragment>
<Bannerproject />
<Project />
<Footer />
</React.Fragment>
    </div>
  )
}

export default Projects
