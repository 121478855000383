import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import {FaFacebookF , FaLinkedinIn , FaTwitter} from 'react-icons/fa'
import {AiFillInstagram} from 'react-icons/ai'

function Teams() {
    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
    };
    
    const [teamMembers, setTeamMembers] = React.useState([]);
    
    React.useEffect(() => {
        fetch('https://admin.casa-eg.com/api/v1/topics/6/page/1/count/10')
            .then(response => response.json())
            .then(data => {
                setTeamMembers(data.topics.map(topic => ({
                    title: topic.title,
                    details: topic.details,
                    photoFile: topic.photo_file
                })));
            })
            .catch(error => console.error(error));
    }, []);
    
    const items = teamMembers.map((member, index) => (
        <div className="item" key={index}>
          <div className="featured-imagebox featured-imagebox-team style1">
            <div className="featured-thumbnail">
              <img
                width="350"
                height="400"
                className="img-fluid"
                src={member.photoFile}
                alt={member.title}
              />
            </div>
            <div className="featured-content">
              <div className="featured-title">
                <h3>
                  <a href="#" tabIndex="0">
                    {member.title}
                  </a>
                </h3>
              </div>
              <p className="team-position">{member.details}</p>
            </div>
          </div>
        </div>
      ));
    
    return (
        <div>
            <AliceCarousel
                mouseTracking
                items={items}
                responsive={responsive}
                controlsStrategy="alternate"
            />
        </div>
    );
}

export default Teams;
