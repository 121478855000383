import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';

function Categoryhome() {

    const [servicesdetail, setServicesdetail] = React.useState([]);

    React.useEffect(() => {
      const fetchData = async () => {
        const result = await axios('https://admin.casa-eg.com/api/v1/topic/8');
        setServicesdetail(result.data.topic);
      };
  
      fetchData();
    }, []);

    const [services, setServices] = React.useState([]);

    React.useEffect(() => {
      const fetchData = async () => {
        const result = await axios('https://admin.casa-eg.com/api/v1/topics/2/page/1/count/3');
        setServices(result.data.topics);
      };
  
      fetchData();
    }, []);

  return (
    <div>
<section class="ttm-row services-section ttm-bgcolor-grey bg-img1 clearfix">
                <div class="container">
             
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="pt-10 text-left res-575-mb-15">
                            {servicesdetail.map((servicedetail) => {
                            return(
                                <div class="section-title">
                                    <div class="title-header">
                                        <h3>Services</h3>
                                        <h2 class="title">Services We’re <strong class="ttm-textcolor-skincolor">Providing</strong></h2>
                                    </div>
                                    <p className="text-justify">{servicedetail.details}</p>
                                </div>
                                )}
                        )
                        }
                                <Link class="ttm-btn ttm-btn-size-md ttm-btn-shape-squar ttm-btn-style-border ttm-icon-btn-right ttm-btn-color-darkgrey mt-15 res-991-mt-0" to="/services" tabindex="0">Read More</Link>
                            </div>
                        </div>
                        {services.map((service,index) => {
                            return(
                        <div class="col-lg-4 col-md-6 col-sm-6" key={index}>
                   
                            <div class="featured-imagebox featured-imagebox-portfolio style2">
                        
                                <div class="featured-thumbnail">
                                <Link to="/services" tabindex="0"><img width="740" height="576" class="img-fluid" src={service.photo_file} alt="image" /></Link>
                                </div>
                                <div class="featured-content">
                               
                                    <div class="featured-title">
                                        <h3><Link to="/services" tabindex="0">{service.title}</Link></h3>
                                    </div>
                                    <div class="featured-desc">
                                    <p className="text-justify">{service.details}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}
                        )
                        }
                        {/* 
                        <div class="col-lg-4 col-md-6 col-sm-6">
                   
                            <div class="featured-imagebox featured-imagebox-portfolio style2">
               
                                <div class="featured-thumbnail">
                                    <a href="#"><img width="740" height="576" class="img-fluid" src={'assets/images/portfolio/portfolio-02-740x576.jpg'} alt="image" /></a>
                                </div>
                                <div class="featured-content">
                                
                                    <div class="featured-title">
                                        <h3><a href="#">Real Estate Development</a></h3>
                                    </div>
                                    <div class="featured-desc">
                                    <p className="text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                   
                            <div class="featured-imagebox featured-imagebox-portfolio style2">
                        
                                <div class="featured-thumbnail">
                                    <a href="#"><img width="740" height="576" class="img-fluid" src={'assets/images/portfolio/portfolio-03-740x576.jpg'} alt="image" /></a>
                                </div>
                                <div class="featured-content">
                            
                                    <div class="featured-title">
                                        <h3><a href="#">Maintenance</a></h3>
                                    </div>
                                    <div class="featured-desc">
                                        <p className="text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                       
                       
                    </div>
                </div>
            </section>


    </div>
  )
}

export default Categoryhome