import React from "react";
import "./BrandStyles.css";
function Featurehome() {
  return (
    <div>
      <section style={{padding:'100px 0'}}>
        <div class="container">
          <div className="row">
            <div class="section-title">
              <div class="title-header">
                <h3>Our Clients</h3>
                <h2 class="title">We Are a real state developer</h2>
              </div>
              <p className="text-justify">
                We Are a real state developement with a focus on creativity ,
                vision and community{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Featurehome;
