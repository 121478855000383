import React from "react";

const SectionTitle = (props) => {
  return (
    <div class="col-lg-9 col-md-9 col-sm-10 m-auto">
      <div class="section-title title-style-center_text">
        <div class="title-header">
          <h3>{props.title}</h3>
          <h2 class="title">{props.description}</h2>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SectionTitle);
