import React from "react";
import { Link } from "react-router-dom";
import "./Projectsnew.css";
import axios from "axios";
import { Puff } from 'react-loader-spinner';

function Projects(props) {
  const [projects, setProjects] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    const fetchCategories = async () => {
      const categoriesResult = await axios(
        "https://admin.casa-eg.com/api/v1/categories/8"
      );
      setCategories(categoriesResult.data.categories);
    };
    fetchCategories();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        "https://admin.casa-eg.com/api/v1/topics/8/page/1/count/1000"
      );
      setProjects(result.data.topics);
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    const categoryButtons = document.querySelectorAll(".category-button");
    categoryButtons.forEach((button) => {
      if (button.textContent === category.title) {
        button.classList.add("selected");
      } else {
        button.classList.remove("selected");
      }
    });
  };
  

  const filteredProjects = selectedCategory
    ? projects.filter((project) => {
        return project.Joined_categories && project.Joined_categories.some((category) => {
          return category.title === selectedCategory.title;
        });
      })
    : projects;
    

  return (
    <div className="ttm-row project-list-section clearfix">
      <div className="container" style={{ display: "flex", justifyContent: "center", alignItems: "center",}}>
        <div className="row">
          <div className="col-lg-12">
            <div className="ttm-project-list-wrapper">
            <div className="ttm-category-buttons-wrapper">
              <div className="category-buttons">
                <button
                  className={`category-button ${selectedCategory === null ? "selected" : ""}`}
                  onClick={() => handleCategoryClick(null)}
                >
                  All
                </button>
                {categories && categories.map((category) => (
                  <button
                    key={category.id}
                    className={`category-button ${selectedCategory?.id === category.id ? "selected" : ""}`}
                    onClick={() => handleCategoryClick(category)}
                  >
                    {category.title}
                  </button>
                ))}
              </div>
              </div>
              {loading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center",}}>
                  <Puff
                    height="80"
                    width="80"
                    radius="9"
                    color="#ad7f47"
                    ariaLabel="loading"
                    wrapperStyle
                    wrapperClass
                    style={{ margin: "auto" }}
                  />
                </div>
              ) : (
                filteredProjects.map((project) => (
                  <div className="ttm-project-list-item">
                    <h2>{project.title}</h2>
                    <div className="ttm_single_image-wrapper mt-15 mb-15 res-991-mt-20">
                      <img
                        width="580"
                        height="610"
                        className="img-fluid"
                        src={project.photo_file}
                        alt={project.title}
                      />
                    </div>
                    <p className="text-justify">{project.details}</p>
                    <Link to={`/${project.id}`}>
                      <button className="ttm-btn ttm-btn-size-md ttm-btn-shape-squar ttm-icon-btn-right ttm-btn-style-fill ttm-btn-color-grey">
                        More Details
                      </button>
                    </Link>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
</div>
  );
}

export default Projects;
