import React from 'react'
import Aboutwrapper from '../components/Aboutwrapper'
import Teams from '../components/Teams'
import Banner from '../components/Banner'
import Footer from '../components/Footer'
import axios from 'axios';




function About() {
  return (
    <div>
    <React.Fragment>
<Banner />
<Aboutwrapper />
<Teams />
<Footer />

    </React.Fragment>
    </div>
  )
}

export default About;