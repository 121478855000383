import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {BsFillTelephoneForwardFill} from 'react-icons/bs'
import {HiOutlineMailOpen} from 'react-icons/hi'
import {BiLocationPlus} from 'react-icons/bi'
import {FaFacebookF , FaLinkedinIn , FaWhatsapp} from 'react-icons/fa'
import { BsInstagram } from 'react-icons/bs'
import axios from 'axios'


const Footer = () => {
    const date = new Date();
    const year = date.getFullYear();

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    fetch('https://admin.casa-eg.com/api/v1/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api_key': '402784613679330'
      },
      body: JSON.stringify({ 
        subscribe_email: email, api_key: 402784613679330
    })
    })
    .then(response => response.json())
    .then(data => {
        setMessage('You are now subscribed to our newsletter!');
        setLoading(false);
    })
    .catch(error => {
        console.error(error);
        setMessage('An error occurred while subscribing, please try again later.');
        setLoading(false);
        });
  };

  const [Contactus, setContactus] = React.useState([]);

    React.useEffect(() => {
      const fetchData = async () => {
        const result = await axios('https://admin.casa-eg.com/api/v1/website/contacts');
        setContactus(result.data.details);
      };
  
      fetchData();
    }, []);

    const [social, setSocial] = React.useState([]);

    React.useEffect(() => {
      const fetchData = async () => {
        const result = await axios('https://admin.casa-eg.com/api/v1/website/social');
        setSocial(result.data.details);
      };
  
      fetchData();
    }, []);

  return (
    <div>

<footer class="footer ttm-bg ttm-bgcolor-darkgrey widget-footer clearfix">
        <div class="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
        <div class="container">
            <div class="first-footer">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 widget-area">
                        <h3>Sign Up To Get Latest Update</h3>
                        <p>Sign up for our monthly newsletter for the latest news &amp; articles</p>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 widget-area">
                        <form id="subscribe-form" class="newsletter-form"  onSubmit={handleSubmit}>
                            <div class="mailchimp-inputbox clearfix" id="subscribe-content"> 
                                <p><input type="email" name="email" placeholder="Enter Your Email" required value={email} onChange={e => setEmail(e.target.value)}/></p>
                               <p><button class="submit ttm-btn ttm-btn-size-md ttm-btn-shape-squar ttm-btn-style-fill ttm-btn-color-skincolor" type="submit">Subscribe Now</button></p>
                               {loading ? (
                                    <div><p>Subscribing...</p></div>
                                ) : (
                                    <div><p>{message}</p></div>
                                )}
                            </div>
                            <div id="subscribe-msg">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="second-footer">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
                        <div class="widget widget_text clearfix">
                            <div class="footer-logo">
                                <img width="205" height="65" id="footer-logo-img" class="img-fluid" src={'assets/images/logo.png'} alt="image" />
                            </div>
                            <div class="textwidget widget-text">
                                <p> </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
                        <div class="widget widget_nav_menu clearfix">
                           <h3 class="widget-title">Quick Links</h3>
                            <ul id="menu-footer-service-link" class="menu">
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/services">Services</Link></li>
                                <li><Link to="/projects">Projects</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                   
                            </ul>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-12 col-lg-4 widget-area">
                        <div class="widget widget-timing clearfix">
                            <h3 class="widget-title">Contact</h3>
                            <div class="featured-icon-box icon-align-before-content icon-ver_align-top style1">
                                <div class="featured-icon">
                                    <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                                    <BsFillTelephoneForwardFill />
                                    </div>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title">
                                        <h3>Phone Number</h3>
                                    </div>
                                    <div class="featured-desc">
                                        <p>{Contactus.phone}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="featured-icon-box icon-align-before-content icon-ver_align-top style1">
                                <div class="featured-icon">
                                    <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                         <HiOutlineMailOpen />
                                    </div>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title">
                                        <h3>Email Address</h3>
                                    </div>
                                    <div class="featured-desc">
                                        <p>{Contactus.email}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="featured-icon-box icon-align-before-content icon-ver_align-top style1">
                                <div class="featured-icon">
                                    <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                                      <BiLocationPlus />
                                    </div>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title">
                                        <h3>Location</h3>
                                    </div>
                                    <div class="featured-desc">
                                        <p>{Contactus.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-footer-text">
                <div class="row copyright">
                    <div class="col-lg-7 col-md-8">
                        <p class="mb-0 res-991-pb-15">Copyright © {year} La Casa. All rights reserved.</p>
                    </div>
                    <div class="col-lg-5 col-md-4">
                        <div class="social-icons d-flex align-items-center justify-content-end">
                            <ul class="list-inline">
                                <li><a href={social.facebook}><FaFacebookF /></a></li>
                                <li><a href={social.instagram}><BsInstagram  /></a></li>
                                <li><a href={social.whatsapp}><FaWhatsapp /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    </div>
  )
}

export default Footer